<template>
  <div>
    <b-row>
      <b-col md="12">
        <AnalyticHeader direcionador />
      </b-col>
    </b-row>
    <section>
      <listDirecionadorOs :status="status" />
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AnalyticHeader from '@/components/indicadores/header.vue'
import listDirecionadorOs from './components/ListDirecionadorOs.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticHeader,
    listDirecionadorOs,
  },
  data() {
    return {
      status: '',
      empresaID: '',
      userId: '',
    }
  },
  async mounted() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.$store.dispatch('direcionador/getTotalizadoresOs', { id: this.empresaID })
  },
}
</script>
